/* ----------------------------*
 * Main Javascript             *
 * ----------------------------*/


 $(function(){

  // Homepage Banner Rotator

   var $rotatingItems = $('.home-slider .banner-item');

   var InfiniteRotator =
    {

        init: function()
        {
          // Make sure the rotating items are styled to 'display: none'.

            //initial fade-in time (in milliseconds)
            var initialFadeIn = 1000;

            //interval between items (in milliseconds)
            var itemInterval = 6000;

            //cross-fade time (in milliseconds)
            var fadeTime = 2500;

            //count number of items
            var numberOfItems = $rotatingItems.length;

            //set current item
            var currentItem = 0;

            //show first item
            $rotatingItems.eq(currentItem).fadeIn(initialFadeIn);

            //loop through the items
            var infiniteLoop = setInterval(function(){
                $rotatingItems.eq(currentItem).fadeOut(fadeTime);

                if(currentItem == numberOfItems -1){
                    currentItem = 0;
                }else{
                    currentItem++;
                }
                $rotatingItems.eq(currentItem).fadeIn(fadeTime);

            }, itemInterval);
        }
    };

    if($rotatingItems.length > 1) {
      InfiniteRotator.init();
    }

 })
